import React from 'react';
import { withTheme } from 'styled-components';

const RemoveIcon = ({
  theme,
  width,
  height,
}: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.1666 16H9.83325" stroke={theme.colors.Secondary} strokeWidth="2" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.5001 29.3333C23.8639 29.3333 29.8334 23.3638 29.8334 16C29.8334 8.63616 23.8639 2.66663 16.5001 2.66663C9.13628 2.66663 3.16675 8.63616 3.16675 16C3.16675 23.3638 9.13628 29.3333 16.5001 29.3333Z" stroke={theme.colors.Secondary} strokeWidth="2" />
    </svg>
  );
};

export default withTheme(RemoveIcon);
