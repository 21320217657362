import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import BoxIcon from '../../../../../assets/icons/box.svg';
import { countriesPostalCodesData } from '../../../../mocks/quoteData';
import { Container, A } from '../../../../../assets/elements';
import device from '../../../../utils/device';

const DeliverBanner = ({ handleEditDetailsClick }: any) => {
  const transformToName = (countryCode: string) => {
    const selected = countriesPostalCodesData.find(
      (element) => element.code === countryCode
    );
    return selected && selected.name;
  };
  const parcel = useSelector<any, any>((state) => state.main.parcel);

  return (
    <Wrapper>
      <ContainerStyled>
        <TextWrapper>
          <TextImg src={BoxIcon} alt="box" />
          <TextP>
            <SpanStyled>
              {transformToName(parcel.collectionLocation.country)}
              {parcel.collectionLocation.postcode &&
                ` (${parcel.collectionLocation.postcode})`}{' '}
              to
              <span> </span>
              {transformToName(parcel.deliveryLocation.country)}
              {parcel.deliveryLocation.postcode &&
                ` (${parcel.deliveryLocation.postcode})`}
              :
            </SpanStyled>
            <span> </span>
            <List>
              {parcel.packageDetails.map((el: any, key: any) => {
                return (
                  <ListItem active={key === 0} key={key}>
                    {`${el.height.amount}x${el.length.amount}x${
                      el.width.amount
                    }cm, ${el.weight.amount}kg (Qty ${key + 1})${
                      key < parcel.packageDetails.length - 1 ? '; ' : ''
                    }`}
                  </ListItem>
                );
              })}
            </List>
            {parcel.packageDetails.length > 1 && (
              <ShowMore>+ {parcel.packageDetails.length - 1} More</ShowMore>
            )}
          </TextP>
        </TextWrapper>
        <AStyled onClick={handleEditDetailsClick}>Edit details</AStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 0;

  @media ${device.laptop} {
    padding: 16px 40px;
  }
`;
const ContainerStyled = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  color: #2b3131;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;
const TextImg = styled.img<any>`
  margin-right: 15px;
  user-select: none;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;
const TextP = styled.p<any>`
  margin-top: 2px;
  line-height: 150%;
`;
const SpanStyled = styled.div`
  font-weight: bold;
  display: inline-block;
`;
const AStyled = styled(A)`
  margin: 10px 0;
  min-width: 80px;
  margin-left: 20px;
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    display: inline-flex;
    flex-direction: row;
  }
`;
const ListItem = styled.li<any>`
  ${({ active }) =>
    !active &&
    `
    display: none;
  `}
  @media ${device.tablet} {
    display: block;
  }
`;
const ShowMore = styled.p<any>`
  @media ${device.tablet} {
    display: none;
  }
`;

export default DeliverBanner;
