import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  A,
  Caption,
  Container,
  H4,
  PSmall,
} from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import InfoIcon from '../../../../../../../../assets/icons/InfoIcon';
import useDebounce from '../../../../../../../utils/useDebounce';
import Loader from '../../../../../../Loader';
import { landingPageUrl } from '../../../../../../../utils/env';
import { fetchInsuranceItems } from '../../../../../../../utils/APICalls/Order';
import Required from '../../../../../../../../assets/elements/Required';

const InsuranceDetails = ({
  insurance,
  changeInsurance,
  shipment,
  error,
  serviceType,
}: any) => {
  const [insuranceData, setInsuranceData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const serviceId = useSelector<any, any>(
    (state: any) => state.order.serviceId
  );
  
  const order = useSelector<any, any>(
    (state: any) => state.order
  );

  let weight = 0;
  if (serviceType === 'pallet') {
    // using weight as quantity: unsure why? WTF
    if(order.dims?.length){
      weight = order.dims.reduce(
        (acc: number, current: any) => acc + current.quantity,
        0
      );
    } else {
      weight = order.packageDetails.pallet.reduce(
        (acc: number, current: any) => acc + current.quantity,
        0
      );
    }
  }

  const debounce = useDebounce(shipment, 2000);

  useEffect(() => {
    if (serviceType === 'freight' || serviceType === 'seaFreight') {
      return;
    }

    let noValue = false;

    shipment.forEach((ship: any) => {
      ship.items.forEach((item: any) => {
        if (item.value === '') {
          noValue = true;
        }
      });
    });

    if (!noValue && debounce) {
      setLoading(true);

      const cost = shipment.reduce(
        (acc: any, ship: any) => Number(acc) + ship.items.reduce((acc2: any, item: any) => {
          return Number(acc2) + (Number(item.value || 0) * Number(item?.quantity || 1));
        }, 0),
        0
      );

      fetchInsuranceItems(cost, serviceId, serviceType, weight)
        .then((res: any) => {
          if (res.data.length > 0) {
            setInsuranceData(
              res.data.map((item: any) => {
                let label = ''
                if (serviceType === 'pallet') {
                  if (item.price === 0) {
                    label = 'Free - included as standard £5 per kilo (e.g - 500kg = £2500)';
                  } else {
                    label = `£${item.price} - additional £${item.level} insurance`;
                  }
                } else {
                  label = `${item.price === 0 ? 'Free' : `£${item.price}`} - Provider covers up to £${item.level}`;
                }
                return {
                  label,
                  value: {
                    ...item,
                    insuranceId: item.id,
                  },
                };
              })
            );
          } else {
            setInsuranceData([]);
          }
          changeInsurance({});
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const handleSDRClick = () => {
    window.open(
      `${landingPageUrl}road-freight`,
      '_blank'
    );
  };

  return (
    <Wrapper>
      <H4Styled>Insurance</H4Styled>
      {
        serviceType === 'freight' || serviceType === 'seaFreight' ? (
          <>
            <PSmallStyled>
              P4D does not currently offer additional insurance{' '}
              for items shipped via a freight network.
              <br />
              P4D’s liability shall be limited to any international convention{' '}
              or national law liability limit that applies compulsorily or,
              should no international convention or national law liability limit apply compulsorily,
              8.33 SDRs per kilo of the Shipments affected.
            </PSmallStyled>
            <LinkContainer>
              <A onClick={handleSDRClick}>
                <Icon><InfoIcon /></Icon>
                About SDRs
              </A>
            </LinkContainer>
          </>
        ) : (
          <>
            <PSmallStyled>
              Insurance is valid providing your goods are not prohibited, restricted
              or unsuitable for a courier network
            </PSmallStyled>
            <SelectContainer>
              <CaptionStyled><Required />Choose your insurance cover level:</CaptionStyled>
              {
                loading ? <Loader>Loading</Loader> :
                <>
                  <InsuranceOptionsContainer>
                    {
                        insuranceData.map(({ label, value }: any, i: any) =>
                          <InsuranceOption
                            key={i}
                            onClick={() => changeInsurance(value)}
                            selected={insurance === value}
                          >
                            { label }
                          </InsuranceOption>
                        )
                      }
                  </InsuranceOptionsContainer>
                  {error && <ErrorText>{error}</ErrorText>}
                </>
              }
            </SelectContainer>
          </>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;

  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 40px;
  }
`;

const H4Styled = styled(H4)`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 27px;
`;

const LinkContainer = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  & > button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Icon = styled.div`
  display: flex;
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 16px;
  display: inline-block;
  font-size: 16px;
  line-height: 150%;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: none;
  margin-top: 4px;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

const InsuranceOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InsuranceOption = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-radius: 4px;
  padding: 12px 14px;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 8px;
  cursor: pointer;
  border: 2px solid ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.colors.WhiteGrey};

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    border: 2px solid ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.colors.Pastel2};
  }
`;

export default InsuranceDetails;
