import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BoxIcon from '../../../../../assets/icons/box.svg';
import { countriesPostalCodesData } from '../../../../mocks/quoteData';
import { Container, A } from '../../../../../assets/elements';
import device from '../../../../utils/device';

const DeliverBanner = ({
  data: { collectionLocation, deliveryLocation, packageDetails, dims },
  handleEditDetailsClick,
}: any) => {
  const [pallets, setPallets] = useState<any>([]);

  const transformToName = (countryCode: string) => {
    const selected = countriesPostalCodesData.find(
      (element) => element.code === countryCode
    );
    return selected && selected.name;
  };

  useEffect(() => {
    const palletsData = [
      packageDetails.full && packageDetails.full.quantity > 0
        ? { name: 'Full', quantity: packageDetails.full.quantity }
        : null,
      packageDetails.half && packageDetails.half.quantity > 0
        ? { name: 'Half', quantity: packageDetails.half.quantity }
        : null,
      packageDetails.light && packageDetails.light.quantity > 0
        ? { name: 'Light', quantity: packageDetails.light.quantity }
        : null,
      packageDetails.mini && packageDetails.mini.quantity > 0
        ? { name: 'Mini', quantity: packageDetails.mini.quantity }
        : null,
      packageDetails.quarter && packageDetails.quarter.quantity > 0
        ? { name: 'Quarter', quantity: packageDetails.quarter.quantity }
        : null,
      dims && dims.length > 0 ? { name: 'Dims', dims } : null,
    ];

    const filteredArray = palletsData.filter((item: any) => item !== null);

    const arrayWithComma = filteredArray.map((el: any, index: any) => {
      if (index < filteredArray.length - 1) {
        el.comma = true;
      }
      return el;
    });
    setPallets(arrayWithComma);
  }, [packageDetails, dims]);

  return (
    <Wrapper>
      <ContainerStyled>
        <TextWrapper>
          <TextImg src={BoxIcon} alt="box" />
          <TextP>
            <SpanStyled>
              {transformToName(collectionLocation.country)}
              {collectionLocation.postcode &&
                ` (${collectionLocation.postcode})`}{' '}
              to
              <span> </span>
              {transformToName(deliveryLocation.country)}
              {deliveryLocation.postcode && ` (${deliveryLocation.postcode})`}:
            </SpanStyled>
            <span> </span>
            {pallets.map((el: any) => {
              return ` ${
                el.name !== 'Dims'
                  ? ` ${el.name} (Qty${el.quantity}) ${el.comma ? ',' : ''}`
                  : el.dims.map((item: any) => {
                      return `${item.length}x${item.width}x${item.height}cm(${item.weight}kg,Qty:${item.quantity})`;
                    })
              }`;
            })}
          </TextP>
        </TextWrapper>
        <AStyled onClick={handleEditDetailsClick}>Edit details</AStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding: 14px 0;
  margin-bottom: 25px;

  @media ${device.laptop} {
    padding: 14px 40px;
  }
`;
const ContainerStyled = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  color: #2b3131;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;
const TextImg = styled.img<any>`
  margin-right: 15px;
  user-select: none;
`;
const TextP = styled.p<any>`
  margin-top: 2px;
`;
const SpanStyled = styled.div`
  font-weight: bold;
  display: inline-block;
`;
const AStyled = styled(A)`
  margin: 10px 0;
  min-width: 80px;
  margin-left: 20px;
`;

export default DeliverBanner;
