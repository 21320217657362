import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SingleMethod from './SingleMethod';
import { Error, P } from '../../../../../../../../assets/elements';
import VisaIcon from '../../../../../../../../assets/icons/visa.png';
import MasterCardIcon from '../../../../../../../../assets/icons/mastercard.png';
import AmexIcon from '../../../../../../../../assets/icons/amex.png';
import PaypalIcon from '../../../../../../../../assets/icons/Paypal.png';
// import KriyaIcon from '../../../../../../../../assets/icons/Kriya.png';
import InfoIcon from '../../../../../../../../assets/icons/Info.svg';
import { getPaymentMethods } from '../../../../../../../utils/APICalls/Order';
import Loader from '../../../../../../Loader';

const paymentMethodsList = [
  {
    id: 'stripe',
    title: 'Credit / Debit card',
    onlyLoggedIn: false,
    images: [
      {
        title: 'Visa',
        url: VisaIcon,
      },
      {
        title: 'Mastercard',
        url: MasterCardIcon,
      },
      {
        title: 'Amex',
        url: AmexIcon,
      },
    ],
  },
  {
    id: 'paypal',
    title: 'Paypal',
    onlyLoggedIn: false,
    images: [
      {
        title: 'Paypal',
        url: PaypalIcon,
      },
    ],
  },
  {
    id: 'kriya',
    title: 'Credit terms (up to 60 days)',
    onlyLoggedIn: false,
    images: [
      // {
      //   title: 'Kriya',
      //   url: KriyaIcon,
      // },
      {
        title: 'Info',
        url: InfoIcon,
      }
    ],
  },
  {
    id: 'directdebit',
    title: 'Weekly invoice',
    onlyLoggedIn: true,
    images: [],
  },
  {
    id: 'balance',
    title: 'Account credit',
    onlyLoggedIn: true,
    images: [],
  },
];

const PaymentMethods = ({ selectedMethod, setSelectedMethod, error }: any) => {
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPaymentMethods()
      .then(({ data }: any) => {
        setPaymentMethods(paymentMethodsList.filter((pm: any) => {
          return data.includes(pm.id);
        }));

        setSelectedMethod(data[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Wrapper>
      <Heading>Payment method</Heading>
      {error && (
        <Error>
          Your payment method was declined. Please select another payment
          method.
        </Error>
      )}
      {
        loading && <Loader>Loading</Loader>
      }
      {!loading && paymentMethods.map((method: any) => (
        <SingleMethod
          method={method}
          key={method.id}
          selected={method.id === selectedMethod}
          setSelected={setSelectedMethod}
        />
      ))}
      {
        !loading && paymentMethods.length === 0 && (
          <P>No payment methods available right now.</P>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 16px;
`;

export default PaymentMethods;
