import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Button, Column, P, Row, A } from '../../../../../assets/elements';
import InfoIcon from '../../../../../assets/icons/Info.svg';
import TransportIcon from '../../../../../assets/icons/Transport-van.svg';
import device from '../../../../utils/device';
import { dateFormat } from '../../../../utils/variables';
import InsuranceInfo from '../InsuranceInfo';

const SingleResult = ({
  id,
  name,
  logo,
  status = '',
  deliveryTime,
  price,
  priceIncVat,
  moreInfo,
  setMoreInfo,
  openMoreInfoModal,
  customs,
  onSelect,
  expires,
  pricesBeforeDiscount,
  coveredInsurance,
  openInsurance,
  setOpenEmailModal,
  shippingDates
}: any) => {
  const handleSubmit = () => {
    onSelect(id);
  };

  const handleMoreInfoClick = () => {
    setMoreInfo(moreInfo);
    ReactTooltip.hide();
    openMoreInfoModal();
  };

  const formatDate = (date: any) => moment(date).format(dateFormat);

  return (
    <Wrapper>
      <RowStyled>
        <ColumnStyled sizeL={2} sizeM={2} sizeXS={4}>
          <Brand>
            <BrandImg src={logo} alt={`${name} logo`} />
          </Brand>
        </ColumnStyled>
        <ColumnStyled sizeL={3} sizeM={10} sizeXS={4}>
          <InfoContainer>
            <TitleContainer>
              <P>{name}</P>
              <IconsContainer>
                <Icon
                  onClick={handleMoreInfoClick}
                  data-tip
                  data-for="info-icon"
                  src={InfoIcon}
                />
                <ReactTooltip id="info-icon" effect="solid">
                  More information
                </ReactTooltip>
                <Icon
                  src={TransportIcon}
                />
              </IconsContainer>
            </TitleContainer>
            <Status>
              Subject to duties and taxes <br />
              Business and commercial shipments only
            </Status>
            <InsuranceInfo coveredInsurance={coveredInsurance} openInsurance={openInsurance} />
          </InfoContainer>
        </ColumnStyled>
        <ColumnStyledSmall sizeL={2} sizeM={3} sizeXS={2}>
          <DeliveryTimeContainer>
            <DeliveryTimeHead>
              Earliest Collection Date
            </DeliveryTimeHead>
            <DeliveryTimeData>
              { moment(shippingDates[0].cd).format(dateFormat) }
            </DeliveryTimeData>
          </DeliveryTimeContainer>
        </ColumnStyledSmall>
        <ColumnStyledSmall sizeL={2} sizeM={3} sizeXS={2}>
          <DeliveryTimeContainer>
            <DeliveryTimeHead>
              Earliest Delivery Date
            </DeliveryTimeHead>
            <DeliveryTimeData>{ moment(shippingDates[0].dd[0]).format(dateFormat) }</DeliveryTimeData>
          </DeliveryTimeContainer>
        </ColumnStyledSmall>
        <PriceColumn sizeL={1.6} sizeM={3} sizeXS={2}>
          <PriceContainer>
            <Price>{`£${price}`}</Price>
            {
              pricesBeforeDiscount && <PriceOriginal>£{ Number(pricesBeforeDiscount.price).toFixed(2) }</PriceOriginal>
            }
            {!customs && (
              <PriceVAT>incl. VAT {`£${priceIncVat}`}</PriceVAT>
            )}
          </PriceContainer>
        </PriceColumn>
        <Column sizeL={1.4} sizeM={3} sizeXS={2}>
          <ButtonContainer>
            <Button
              onClick={handleSubmit}
              color="secondary"
            >
              Book
            </Button>
            <LinkWrapper onClick={() => setOpenEmailModal(true)}>Email Quote</LinkWrapper>
          </ButtonContainer>
        </Column>
      </RowStyled>
    </Wrapper>
  );
};
const Wrapper = styled.div`
    background: white;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 4px;
`;

const RowStyled = styled(Row)`
  width: 100%;
  padding: 24px;
`;

const ColumnStyled = styled(Column)`
  margin-bottom: 26px;

  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;

const ColumnStyledSmall = styled(Column)`
  margin-bottom: 26px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

const Brand = styled.div``;
const BrandImg = styled.img`
  max-height: 88px;
  width: auto;
  max-width: 100px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`;

const Status = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #777e7e;
  flex: none;
  order: 1;
  align-self: flex-start;
`;

const Icon = styled.img`
  margin-left: 25px;
`;

const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 0 4px;
  text-align: center;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 5px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
`;

const PriceHead = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const Price = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const PriceVAT = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const IconsContainer = styled.div`
  display: flex;
`;

const PriceColumn = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PriceOriginal = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: line-through;
  margin-bottom: 4px;
`;

const LinkWrapper = styled(A)`
width: 100%;
text-align: center;
margin-top: 0.7rem;
font-size: 0.8rem;
white-space: nowrap;
    &:hover {
    color: ${({ theme }: any) => theme.colors.SecondaryHover};
  }
`;

export default SingleResult;
