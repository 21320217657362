import React from 'react';
import styled from 'styled-components';
import InsuranceIcon from '../../../../../assets/icons/insurance.svg';
import { A } from '../../../../../assets/elements';

const InsuranceInfo = ({ coveredInsurance, openInsurance, isPallet, weight }: any) => {
  return (
    coveredInsurance > 0 ? (
      <Wrapper>
        <Icon src={InsuranceIcon} alt='I' />
        <Text>
          {isPallet
            ? <span>Including premium insurance of £5 per kilo against any damage or loss (e.g - 500kg = £2500)</span>
            : <span>Including insurance up to £{ coveredInsurance }</span>}
          { !isPallet && <AStyled onClick={openInsurance}>Need more?</AStyled> }
        </Text>
      </Wrapper>
    ) : <></>
  );
};

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  flex: none;
  align-self: flex-start;
  margin-top: 8px;
`;

const Icon = styled.img``;

const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 6px;
`;

const AStyled = styled(A)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  text-decoration: underline;
`;

export default InsuranceInfo;
