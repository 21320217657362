import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import SingleResultFreight from './SingleResult';
import SingleResultPallet from '../PalletQuotes/SingleResult';
import { Container, PSmall } from '../../../../../assets/elements';
import {
  setItemDetails,
  setFreightQuotes,
  setSelectedService,
  setFreightValues,
  setPalletQuotes,
  setFreightQuotesSortedAction,
  resetQuotes, setPallet,
} from '../../../../store/actions';
import NotFound from '../../../NotFound';
import Modal from '../../../../../assets/Combos/Modal';
import StepOneFreight from '../../../LandingPageQuote/components/QuoteForm/Freight/StepOne';
import Sort from '../../../Sort';
import { sort } from '../../../../utils/sorting';
import DeliverBanner from './DeliverBanner';
import { GTMECommerce } from '../../../../utils/tracking';
import device from '../../../../utils/device';
import QuoteLoader from '../../../QuoteLoader';
import EmailQuote from '../EmailQuote';
import ManualQuoteRequest from '../ManualQuoteRequest/ManualQuoteRequest';

const sortOptions = [
  {
    id: 0,
    label: 'Cheapest',
  },
  {
    id: 1,
    label: 'Quickest',
  },
];

export const SortValuesMap = {
  CHEAPEST: 0,
  QUICKEST: 1,
};

const FreightQuotes = ({ openInsurance, openEmailModal, setOpenEmailModal }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    collection,
    delivery,
    date,
    items: freightItems,
  } = useParams();
  let initial = true;

  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>();
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [sortSelected, setSortSelected] = useState(0);
  const [collectionDate, setCollectionDate] = useState<any>('');
  const [collectionDateError, setCollectionDateError] = useState(false);
  const [minDate, setMinDate] = useState<any>(moment(new Date()));
  const [loadType, setLoadType] = useState('');

  const pallet = useSelector<any, any>((state) => state.main.pallet);

  const quotes = useSelector<any, any>((state) => state.main.freightQuotes);

  const freight = useSelector<any, any>((state) => state.main.freight);

  const loading = useSelector<any, any>((state) => state.main.loading.quotes);

  useEffect(() => {
    if (collection && !freight?.collectionLocation.country) {
      const collectionData = collection.split(':');
      const deliveryData = delivery.split(':');

      !collectionData[0] &&
        freight?.collectionLocation.country === '' &&
        history.push('/');

      const packageDetails = freightItems.split(',');
      moment.locale('en-GB');

      setCollectionDate(moment(date).format());

      const dateArray = date.split("-")

      dispatch(
        setFreightValues({
          collectionLocation: {
            country: collectionData[0],
            postcode: !collectionData[1] ? '' : collectionData[1],
          },
          deliveryLocation: {
            country: deliveryData[0],
            postcode: deliveryData[1] || '',
          },
          packageDetails: {
            numPallets: Number(packageDetails[1]),
            loadType: packageDetails[0],
          },
          readyDate: new Date(dateArray[2], dateArray[1] - 1, dateArray[0]),
          loadType: packageDetails[0],
        })
      );

      if (Number(packageDetails[1]) < 6 && packageDetails[0] === 'LTL') {
        setLoadType(packageDetails[0]);
        dispatch(
          setPallet({
            collectionLocation: {
              country: collectionData[0],
              postcode: !collectionData[1] ? '' : collectionData[1],
            },
            deliveryLocation: {
              country: deliveryData[0],
              postcode: deliveryData[1] || '',
            },
            packageDetails: {
              mini: {
                palletSizeId: 1,
                quantity: '',
              },
              quarter: {
                palletSizeId: 2,
                quantity: '',
              },
              half: {
                palletSizeId: 3,
                quantity: '',
              },
              light: {
                palletSizeId: 5,
                quantity: '',
              },
              full: {
                palletSizeId: 6,
                quantity: Number(packageDetails[1]),
              },
            },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initial && quotes.length) {
      initial = false;

      let ecd = moment(quotes[0]?.earliestCollectionDate);

      quotes.forEach((q: any) => {
        if (ecd.diff(q.earliestCollectionDate, 'days') > 0) {
          ecd = moment(q.earliestCollectionDate);
        }
      });

      setMinDate(ecd);
    }
  }, [quotes]);

  useEffect(() => {
    dispatch(setFreightQuotesSortedAction([]));

    if (freight?.collectionLocation.country !== '') {
      dispatch(setFreightQuotes(generateRequest(), false, false, true));

      if (freight?.packageDetails.numPallets < 6 && freight.packageDetails.loadType === 'LTL') {
        dispatch(setPalletQuotes(generatePalletRequest(), false, true, true));
      }
    }
  }, [freight, dispatch]);

  useEffect(() => {
    if (moment(collectionDate).diff(moment(), 'days') > 10) {
      dispatch(setFreightQuotesSortedAction(
        quotes.map((item: any) => {
          if (item.quoteType === 'pallet') {
            item.active = false;
          }

          return item;
        })
      ));
    } else {
      dispatch(setFreightQuotesSortedAction(
        quotes.map((item: any) => {
          item.active = true;

          return item;
        })
      ));
    }
  }, [collectionDate]);

  const generateRequest = () => {
    const temp = { ...freight };

    temp.numPallets = temp.packageDetails.numPallets;
    delete temp.packageDetails;

    return temp;
  };

  const generatePalletRequest = () => {
    const temp = { ...freight };

    const { numPallets } = temp.packageDetails;

    temp.packageDetails = [{
      quantity: numPallets,
      palletSizeId: 6,
    }];

    return temp;
  };

  useEffect(() => {
    if (moreInfoValue.bookingIn) {
      setMoreInfoText(
        <>
          <PSmallStyled>{moreInfoValue.bookingIn}</PSmallStyled>
          <ul>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Cancellation: {moreInfoValue.cancellation}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Collection time: {moreInfoValue.collectionTime}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Demurrage: { moreInfoValue.demurrage }
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Insurance: { moreInfoValue.insurance }
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Trailer: { moreInfoValue.trailer }
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Weight: { moreInfoValue.weight }
              </PSmall>
            </Li>
          </ul>
        </>
      );
    }
  }, [moreInfoValue]);

  const setMoreInfoData = (value: any) => {
    setMoreInfoValue(value);
  };

  const handleSortChange = (data: any) => {
    setSortSelected(data);

    let payload;

    if (data === SortValuesMap.CHEAPEST) {
      payload = sort(quotes, 'price');
    } else if (data === SortValuesMap.QUICKEST) {
      payload = sort(quotes, 'deliveryDate', true);
    }

    dispatch(setFreightQuotesSortedAction(payload));
  };

  const changeCollectionDate = (value: any) => {
    if (value !== '') {
      setCollectionDateError(false);
    }

    setCollectionDate(value);
  };

  const handleSelect = (selected: any, type: any = 'freight') => {
    let position = 0;
    if (!collectionDate || collectionDate === '') {
      setCollectionDateError(true);

      return;
    }

    const selectedQuote = quotes.filter(
      ({ serviceId }: any, i: any) => {
        if (serviceId === selected) {
          position = i + 1;
          return true;
        }

        return false;
      }
    )[0];

    const tempQuote = cloneDeep(selectedQuote);

    tempQuote.collectionDate = moment(collectionDate).format('yyyy-MM-DD');

    if (tempQuote.quoteType === 'pallet') {
      tempQuote.deliveryDate =
        moment(collectionDate).add(tempQuote.deliveryTime, 'days').format('yyyy-MM-DD');

      if (
        moment(tempQuote.deliveryDate).isoWeekday() === 6 ||
        moment(tempQuote.deliveryDate).isoWeekday() === 7) {
        tempQuote.deliveryDate = moment(tempQuote.deliveryDate).add(2, 'days').format('yyyy-MM-DD');
      }
    }

    GTMECommerce(
      {
        click: {
          products: [{
            name: selectedQuote.name,
            id: `${selectedQuote.rateId}-${selectedQuote.serviceId}-freight`,
            price: selectedQuote.priceIncVat,
            category: 'freight',
            variant: 'collection',
            position,
          }],
        }
      },
      'productClick',
    );

    dispatch(setSelectedService(tempQuote, selectedQuote.quoteType || 'freight'));
    if (type === 'freight') {
      dispatch(setItemDetails(freight, selectedQuote.quoteType || 'freight', loadType ));
    } else {
      dispatch(setItemDetails(freightToPallet(), selectedQuote.quoteType || 'freight'));
    }
    dispatch(resetQuotes());

    history.push('/order-step1');
  };

  const freightToPallet = () => {
    return {
      collectionLocation: { ...freight.collectionLocation },
      deliveryLocation: { ...freight.deliveryLocation },
      palletAndWrap: false,
      packageDetails: [{
        palletSizeId: 6,
        quantity: freight.packageDetails.numPallets,
      }]
    };
  };

  const notFoundText =
    'Sorry! No results found. Please remove any filters or update your item details to find a quote';

  const noQuotesFound = quotes.length === 0 && (
    <NotFound>{notFoundText}</NotFound>
  );

  const SortJSX = (
    <SortWrapper>
      <Sort
        options={sortOptions}
        selected={sortSelected}
        onChange={handleSortChange}
      />
    </SortWrapper>
  );

  const quotesDisplay =
    quotes.filter(({ active }: any) => active).length === 0 ? (
      <NotFound>{notFoundText}</NotFound>
    ) : (
      quotes.map(
        ({
          serviceId: id,
          name,
          logo,
          status,
          expires,
          deliveryTime,
          deliveryDate,
          deliveryDays,
          earliestCollectionDate,
          price,
          priceIncVat,
          active,
          moreInfo,
          quoteType,
          timed,
          vat,
          timeslots,
          pricesBeforeDiscount,
          coveredInsurance = 0,
          shippingDates
        }: any) => {
          return (
            active && (
              quoteType === 'freight' ?
                <SingleResultFreight
                  key={id}
                  id={id}
                  name={name}
                  logo={logo}
                  status={status}
                  deliveryTime={deliveryTime}
                  expires={expires}
                  price={price}
                  priceIncVat={priceIncVat}
                  customs={vat.customs}
                  moreInfo={moreInfo}
                  setMoreInfo={setMoreInfoData}
                  openMoreInfoModal={() => setMoreInfoModal(true)}
                  onSelect={handleSelect}
                  pricesBeforeDiscount={pricesBeforeDiscount}
                  coveredInsurance={coveredInsurance}
                  openInsurance={openInsurance}
                  setOpenEmailModal={setOpenEmailModal}
                  shippingDates={shippingDates}
                /> :
                <SingleResultPallet
                  key={id}
                  id={id}
                  name={name}
                  logo={logo}
                  status={status}
                  deliveryTime={deliveryTime}
                  deliveryDate={deliveryDate}
                  deliveryDays={deliveryDays}
                  earliestCollectionDate={earliestCollectionDate}
                  price={price}
                  priceIncVat={priceIncVat}
                  customs={vat.customs}
                  moreInfo={moreInfo}
                  setMoreInfo={setMoreInfoData}
                  openMoreInfoModal={() => setMoreInfoModal(true)}
                  selectedCD={shippingDates[0].cd}
                  selectedDD={deliveryDate}
                  onSelect={(value: any) => handleSelect(value, 'pallet')}
                  timed={timed}
                  timeslots={timeslots}
                  pricesBeforeDiscount={pricesBeforeDiscount}
                  coveredInsurance={coveredInsurance}
                  openInsurance={openInsurance}
                  pallet={pallet}
                  setOpenEmailModal={setOpenEmailModal}
                  shippingDates={shippingDates}
                />
            )
          );
        }
      )
    );

  const toggleEditDetailsModal = (value: boolean) => {
    setEditDetailsModal(value);
  };

  const handleEditDetailsClick = () => {
    setResetValues(true);
    toggleEditDetailsModal(true);
  };

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoData({});
  };

  return (
    <>
      <DeliverBanner
        data={freight}
        handleEditDetailsClick={handleEditDetailsClick}
      />
      <Container>
        <ResultsWrapper>
          {loading ? (
            <QuoteLoader />
          ) : (
            quotes.length === 0 ? <ManualQuoteRequest type='freight'/> : (
              <>
                <FilterWrapper>
                  {SortJSX}
                </FilterWrapper>
                <EmailWrapper>
                  <EmailQuote packageType='freight' open={openEmailModal} setOpen={setOpenEmailModal} />
                </EmailWrapper>
                {quotesDisplay}
              </>
            )
          )}
        </ResultsWrapper>
      </Container>
      <Modal
        title="Edit details"
        open={editDetailsModal}
        close={() => toggleEditDetailsModal(false)}
        width={10}
      >
        <StepOneFreight
          resetValues={resetValues}
          setResetValues={setResetValues}
          onQuotesPage={true}
          closeModal={() => toggleEditDetailsModal(false)}
        />
      </Modal>
      <Modal
        title="More information"
        open={moreInfoModal}
        close={() => handleMoreInfoClose()}
        back={true}
        width={8}
      >
        {moreInfoText}
      </Modal>
    </>
  );
};

const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterWrapper = styled.div`
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const EmailWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export default FreightQuotes;
